import React, { useState, useEffect, useContext } from "react";
import "../../assets/style/globalStyle.scss";
import { navigate } from "gatsby";
import {
  getAllPlaygrounds,
  getPlaygroundById,
} from "../../services/playgroundService";

import { GlobalDispatchContext } from "../../context/GlobalContextProvider";

const AllPlaygrounds = () => {
  const dispatch = useContext(GlobalDispatchContext);

  const [isUrgentShow, setIsUrgentShow] = useState(true);
  const [isToContactShow, setIsToContactShow] = useState(true);
  const [isContactedShow, setIsContactedShow] = useState(true);
  const [playgroundsToContact, setPlaygroundsToContact] = useState([]);
  const [playgroundsUrgent, setPlaygroundsUrgent] = useState([]);
  const [playgroundsContacted, setPlaygroundsContacted] = useState([]);

  const getPlaygroundsToModerate = async () => {
    const data = await getAllPlaygrounds();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>Not have data !</h1>
        </div>
      );
    }

    for (let i = 0; i < data.playgroundToModerateFalse.length; i++) {
      let playground = data.playgroundToModerateFalse[i];

      if (playground.resendDate !== undefined) {
        setPlaygroundsContacted((playgroundsContacted) => [
          ...playgroundsContacted,
          playground,
        ]);
      } else if (playground.image.length === 3 && !playground.infoNotValide) {
        setPlaygroundsUrgent((playgroundsUrgent) => [
          ...playgroundsUrgent,
          playground,
        ]);
      } else {
        setPlaygroundsToContact((playgroundsToContact) => [
          ...playgroundsToContact,
          playground,
        ]);
      }
    }
  };

  const GoToPlayground = async (id) => {
    const playground = await getPlaygroundById(id);
    dispatch({
      type: "load_playground",
      payload: playground.playground,
      author: playground.authorEmail,
    });
    navigate("/app/updatePlaygroundPage");
  };

  useEffect(() => {
    getPlaygroundsToModerate();
  }, []);
  return (
    <div>
      <div className="box">
        <div className="columns">
          <div className="column">
            <p className="label">Playgrounds avec toutes leurs images</p>
          </div>
          <div className="column">
            <button
              className="button is-primary is-outlined"
              onClick={() => {
                setIsUrgentShow(!isUrgentShow);
              }}
            >
              {isUrgentShow
                ? "Masquer les playgrounds entièrement complets"
                : "Afficher les playgrounds entièrement complets"}
            </button>
          </div>
        </div>
        {isUrgentShow && (
          <table className="table is-striped is-hoverable is-responsive">
            <thead>
              <tr>
                <th>
                  <abbr title="Nom du terrain">Nom du terrain</abbr>
                </th>
                <th style={{ width: "160px" }}>
                  <abbr title="Ville">Ville</abbr>
                </th>
                <th className="is-hidden-mobile" style={{ width: "160px" }}>
                  <abbr title="Code postal">Code postal</abbr>
                </th>
                <th className="is-hidden-mobile">
                  <abbr title="Région">Région</abbr>
                </th>
                <th className="is-hidden-mobile">
                  <abbr title="Photo">Photo</abbr>
                </th>
              </tr>
            </thead>

            <tbody>
              {playgroundsUrgent.length !== 0 &&
                playgroundsUrgent
                  .sort(
                    (a, b) =>
                      new Date(b.createdAt.slice(0, 10)) -
                      new Date(a.createdAt.slice(0, 10))
                  )
                  .map((playground) => (
                    <tr key={playground.name} style={{ height: "45px" }}>
                      <td>{playground.name}</td>
                      <td>{playground.city}</td>
                      <td className="is-hidden-mobile">{playground.zipCode}</td>
                      <td className="is-hidden-mobile">{playground.state}</td>
                      <td className="is-hidden-mobile">
                        {playground.image.length}
                      </td>
                      <td>
                        <button
                          className="button is-primary"
                          onClick={() => GoToPlayground(playground._id)}
                        >
                          <span className="icon is-medium">
                            <span className="fa-stack">
                              <i className="fas fa-circle fa-stack-2x"></i>
                            </span>
                          </span>
                        </button>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        )}
      </div>

      <div className="box">
        <div className="columns">
          <div className="column">
            <p className="label">Playgrounds à relancer</p>
          </div>
          <div className="column">
            <button
              className="button is-primary is-outlined"
              onClick={() => {
                setIsToContactShow(!isToContactShow);
              }}
            >
              {isToContactShow
                ? "Masquer les playgrounds non complets"
                : "Afficher les playgrounds non complets"}
            </button>
          </div>
        </div>
        {isToContactShow && (
          <table className="table is-striped is-hoverable is-responsive">
            <thead>
              <tr>
                <th>
                  <abbr title="Nom du terrain">Nom du terrain</abbr>
                </th>
                <th style={{ width: "160px" }}>
                  <abbr title="Ville">Ville</abbr>
                </th>
                <th className="is-hidden-mobile" style={{ width: "160px" }}>
                  <abbr title="Code postal">Code postal</abbr>
                </th>
                <th className="is-hidden-mobile">
                  <abbr title="Région">Région</abbr>
                </th>
                <th className="is-hidden-mobile">
                  <abbr title="Photo">Photo</abbr>
                </th>
              </tr>
            </thead>

            <tbody>
              {playgroundsToContact.length !== 0 &&
                playgroundsToContact
                  .sort(
                    (a, b) =>
                      new Date(b.createdAt.slice(0, 10)) -
                      new Date(a.createdAt.slice(0, 10))
                  )
                  .map((playground) => (
                    <tr key={playground.name} style={{ height: "45px" }}>
                      <td>{playground.name}</td>
                      <td>{playground.city}</td>
                      <td className="is-hidden-mobile">{playground.zipCode}</td>
                      <td className="is-hidden-mobile">{playground.state}</td>
                      <td className="is-hidden-mobile">
                        {playground.image.length}
                      </td>
                      <td>
                        <button
                          className="button is-primary"
                          onClick={() => GoToPlayground(playground._id)}
                        >
                          <span className="icon is-medium">
                            <span className="fa-stack">
                              <i className="fas fa-circle fa-stack-2x"></i>
                            </span>
                          </span>
                        </button>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        )}
      </div>

      <div className="box">
        <div className="columns">
          <div className="column">
            <p className="label">Playgrounds contacté</p>
          </div>
          <div className="column">
            <button
              className="button is-primary is-outlined"
              onClick={() => {
                setIsContactedShow(!isContactedShow);
              }}
            >
              {isContactedShow
                ? "Masquer les playgrounds déjà contactés"
                : "Afficher les playgrounds déjà contactés"}
            </button>
          </div>
        </div>
        {isContactedShow && (
          <table className="table is-striped is-hoverable is-responsive">
            <thead>
              <tr>
                <th>
                  <abbr title="Nom du terrain">Nom du terrain</abbr>
                </th>
                <th style={{ width: "160px" }}>
                  <abbr title="Ville">Ville</abbr>
                </th>
                <th className="is-hidden-mobile">
                  <abbr title="Photo">Photo</abbr>
                </th>
                <th className="is-hidden-mobile">
                  <abbr title="Photo">Jour de relance</abbr>
                </th>
              </tr>
            </thead>

            <tbody>
              {playgroundsContacted.length !== 0 &&
                playgroundsContacted
                  .sort(
                    (a, b) =>
                      new Date(b.createdAt.slice(0, 10)) -
                      new Date(a.createdAt.slice(0, 10))
                  )
                  .map((playground) => (
                    <tr key={playground.name} style={{ height: "45px" }}>
                      <td>{playground.name}</td>
                      <td>{playground.city}</td>
                      <td className="is-hidden-mobile">
                        {playground.image.length}
                      </td>
                      <td className="is-hidden-mobile">
                        {playground.resendDate.slice(0, 10)}
                      </td>
                      <td>
                        <button
                          className="button is-primary"
                          onClick={() => GoToPlayground(playground._id)}
                        >
                          <span className="icon is-medium">
                            <span className="fa-stack">
                              <i className="fas fa-circle fa-stack-2x"></i>
                            </span>
                          </span>
                        </button>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default AllPlaygrounds;
